<template>
  <div v-loading="isLoading" class="px-8 relative">
    <div v-if="isMultipleImages">
      <div v-for="(contentItem,index) in content" :key="index">
        <div class="mx-auto" v-html="contentItem"></div>
      </div>
    </div>
    <div v-else class="mx-auto" v-html="content"></div>
    <!-- <div @click="()=>{$router.go(-1)}">
      <svg class="mr-3" width="33" height="33" viewBox="0 0 33 33" fill="none">
        <rect
          x="23.2937"
          y="8.11432"
          width="2"
          height="22"
          rx="1"
          transform="rotate(45 23.2937 8.11432)"
          fill="#4F4F4F"
        />
        <rect
          x="24.7079"
          y="23.6707"
          width="2"
          height="22"
          rx="1"
          transform="rotate(135 24.7079 23.6707)"
          fill="#4F4F4F"
        />
      </svg>
    </div>-->
  </div>
</template>

<script>
import { isArray } from 'lodash'
export default {
  name: 'UptodateGraphic',
  data () {
    return {
      graphicData: {},
      content: '',
      isLoading: false
    }
  },
  computed: {
    isMultipleImages () {
      return isArray(this.content)
    }
  },
  created () {
    // console.log(this.$route.query)

    this.handleGetGraphic()
  },
  methods: {
    async handleGetGraphic () {
      try {
        this.isLoading = true
        const objQuery = this.$route.query

        const imageId = this.handleParseQueryImageKey(objQuery?.imageKey)

        const params = {
          image_key: objQuery?.imageKey,
          topic_key: objQuery?.topicKey,
          source: objQuery?.source,
          id: imageId
        }

        let response

        if (isArray(imageId)) {
          response = await this.$rf
            .getRequest('DoctorRequest')
            .getUptodateMultipleImages(params)
        } else {
          response = await this.$rf
            .getRequest('DoctorRequest')
            .getUptodateGraphic(params)
        }

        this.graphicData = response.data.data || {}

        if (response.data?.data?.imageHtml) {
          this.content = this.handleFormatContent(
            response.data?.data?.imageHtml
          )
        } else {
          this.content = response.data.data?.graphics?.map(item => {
            return this.handleFormatContent(item?.imageHtml)
          })
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('Có lỗi xảy ra khi lấy dữ liệu'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleFormatContent (html) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')
      const imgElements = doc.querySelectorAll('img')

      imgElements.forEach(img => {
        const src = img.getAttribute('src')

        const newSrc = `https://www.uptodate.com${src}`
        img.setAttribute('src', newSrc)
      })

      return doc.documentElement.outerHTML
    },
    handleParseQueryImageKey (query) {
      if (!query.includes('~')) return query?.split('/')?.[1]
      const parts = query.split('~')
      const values = []

      for (const part of parts) {
        const startIndex = part.indexOf('DERM/') + 5
        const endIndex = part.length
        const value = part.substring(startIndex, endIndex)
        values.push(value)
      }

      return values
    }
  }
}
</script>

<style lang="scss" scoped>
</style>